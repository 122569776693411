const state = {
    whiteBackground: true
}
const mutations = {
    setBackground(state, data) {
        state.whiteBackground = data
    }
}
const actions = {
    changeBackground({commit}, param) {
        commit('setBackground', param)
    }
}
const getters = {
    hasWhiteBackground: (state) => {
        return state.whiteBackground
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
