import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios-plugin'
import {i18n} from '@/plugins/i18n-plugin'
import components from '@/plugins/component-plugin'
import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'

window.toastr = require('toastr')

Vue.use(axios)
Vue.use(VueToastr2)
Vue.use(components)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)


Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount('#app')
