export const AUTHORIZATION = 'X-AUTH-TOKEN'

export const API_SERVER = 'https://giveaway-api.websa.tech'

export const BASE_URL = '/api/v1'

export const HEADER_LANGUAGE = 'Accept-Language'
export const DEFAULT_LANGUAGE = 'ru'

export const FILE_TYPE_IMAGE = 'image/jpeg'
