<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
    import {AUTHORIZATION} from './constants/constants'

    export default {
        name: 'app',
        async created() {
            await this.$store.dispatch('fetch', localStorage.getItem(AUTHORIZATION), {root: true})
                .then(data => {
                    if (data && data.status !== 200) {
                        this.$toastr.error(data.message)
                        this.$router.push('/login')
                    }
                })
        }
    }
</script>

<style >
  @import '../node_modules/bootstrap/dist/css/bootstrap.css';
  @import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
  @import 'assets/fontawesome-free/css/all.css';
  @import '../node_modules/vue-popperjs/dist/vue-popper.css';
  @import '../node_modules/gridstack/dist/gridstack.css';
  @import 'assets/css/main.css';
  @import 'assets/css/custom.css';
  @import 'assets/css/metisMenu.min.css';
</style>
