import Vue from 'vue'
import Router from 'vue-router'
import beforeEnter from '@/utils'

const LoginPage = () => import('@/pages/LoginPage')
const DefaultLayout = () => import('@/layouts/DefaultLayout')
const CompetitionsListPage = () => import('@/pages/competition/CompetitionsListPage')
const CompetitionsAddEditPage = () => import('@/pages/competition/CompetitionsAddEditPage')
const CompetitionsSummaryPage = () => import('@/pages/competition/CompetitionsSummaryPage')
const UsersListPage = () => import('@/pages/user/UsersListPage')
const WinnerGenerate = () => import('@/pages/winners/GenerateWinner')
const AnswerMessagesListPage = () => import('@/pages/answer-messages/AnswerMessagesListPage')
const AnswerMessagesAddEditPage = () => import('@/pages/answer-messages/AnswerMessagesAddEditPage')

const NotFoundPage = () => import('@/pages/NotFoundPage.vue')

Vue.use(Router)

export default new Router({
    mode: 'hash',
    linkActiveClass: 'open active',
    linkExactActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            name: 'Home',
            redirect: '/competitions',
            component: DefaultLayout,
            beforeEnter: beforeEnter,
            children: [
                {
                    path: 'competitions',
                    name: 'Competitions',
                    redirect: '/competitions/list',
                    beforeEnter: beforeEnter,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'CompetitionsListPage',
                            component: CompetitionsListPage
                        },
                        {
                            path: 'add',
                            name: 'CompetitionsAdd',
                            component: CompetitionsAddEditPage
                        },
                        {
                            path: 'edit/:id',
                            name: 'CompetitionsEdit',
                            component: CompetitionsAddEditPage
                        },
                        {
                            path: 'summary/:id',
                            name: 'CompetitionsSummary',
                            component: CompetitionsSummaryPage
                        }
                    ]
                },
                {
                    path: 'user',
                    name: 'Users',
                    redirect: '/user/list',
                    beforeEnter: beforeEnter,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'UsersListPage',
                            component: UsersListPage
                        },
                    ]
                },
                {
                    path: 'winners',
                    name: 'winners',
                    beforeEnter: beforeEnter,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'generate',
                            name: 'WinnerGenerate',
                            component: WinnerGenerate
                        },
                    ]
                },
                {
                    path: 'answer-messages',
                    name: 'answer-messages',
                    redirect: '/competitions/list',
                    beforeEnter: beforeEnter,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'AnswerMessagesList',
                            component: AnswerMessagesListPage
                        },
                        {
                            path: 'edit/:id',
                            name: 'AnswerMessagesAddEdit',
                            component: AnswerMessagesAddEditPage
                        }
                    ]
                },
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginPage
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFoundPage
        },
        {
            path: '*',
            component: NotFoundPage
        }
    ]
})
